import {useContext} from "react";
import { Col, Row} from 'antd';
import DashBoardHeader from "../elements/Header";
import RepoList from "../elements/RepoList";
import {UserContext} from "../../../providers/UserProvider";
import * as React from "react";


const gutter = {xs: 8, sm: 16, md: 24, lg: 32}


export default function Repos() {
    const {organisation} = useContext(UserContext);

    if (organisation?.accessRights?.gitProviders?.length > 0)
        return (
            <div>
                <DashBoardHeader title={"Code Repositories"} hideAlerts={true}/>
                <Row gutter={gutter}>
                    <Col span={24}>
                        <RepoList/></Col>
                </Row>
            </div>

        );

    window.gtag('event', 'repo_view', {
        event_category: 'Onboarding',
        event_label: 'User can see linked repos.',
    });
    return (
        <div>
            <DashBoardHeader title={"Code Repositories"} hideAlerts={false} hideButtons={true}/>
        </div>

    );


}
