import * as React from "react";
import {Button, Card, Col, Modal, Row, Space, Table} from "antd";
import {PageCard} from "../dashboard/elements/BaseCard";
import {CheckOutlined, BranchesOutlined} from "@ant-design/icons";
import {useContext, useState} from "react";
import {AccessRightsContext} from "../../providers/AccessRightsProvider";
import AzureDevModal from "./modals/AzureDevModal";
import {redirectToGitlabAuth} from "../../config/appConfig";

const gutter = {xs: 8, sm: 16, md: 24, lg: 32}

function GitModal({visible, onCancel}) {

    const [azuredevModal, setAzuredevModal] = useState(false);

    return (
        <Modal centered open={visible} className={"with-background"}
               onCancel={onCancel} width={700} footer={[]}>
            <div className="modal-background"></div>
            <div className="modal-icon"><BranchesOutlined/></div>
            <h2 style={{marginRight: 20, marginTop: -5}}>Add Git Provider</h2>
            <Card style={{marginBottom: 0, maxHeight: "70vh", overflow: "auto"}}>
                <p>We support Bitbucket, Github & Azure DevOps integration.</p>
                <Row gutter={gutter} style={{marginTop: 40}}>
                    <Col span={8}>
                        <Button className="git-install" href={window._env_.GITHUB_REDIRECT_URL}>
                            <Row
                                type="flex"
                                justify="center"
                                align="middle"
                                style={{minHeight: '100%'}}
                            >
                                <Col>
                                    <img alt="github" src="/icons/github.png" width={80}/>
                                </Col>
                            </Row>

                        </Button>
                    </Col>
                    <Col span={8}>
                        <Button className="git-install"
                                href={window._env_.BITBUCKET_REDIRECT_URL}>
                            <Row
                                type="flex"
                                justify="center"
                                align="middle"
                                style={{minHeight: '100%'}}
                            >
                                <Col>
                                    <img alt="bitbucket" src="/icons/bitbucket.png" width={120}/>
                                </Col>
                            </Row>

                        </Button>
                    </Col>
                    <Col span={8}>
                        <Button className="git-install" onClick={() => setAzuredevModal(true)}  >
                            <Row
                                type="flex"
                                justify="center"
                                align="middle"
                                style={{minHeight: '100%'}}
                            >
                                <Col>
                                    <img alt="azuredev" src="/icons/azure-devops.png" width={100}/>
                                </Col>
                            </Row>

                        </Button>
                        <AzureDevModal open={azuredevModal} onClose={() => setAzuredevModal(false)}  />
                    </Col>
                    <Col span={8}>
                        <Button className="git-install" onClick={redirectToGitlabAuth}  >
                            <Row
                                type="flex"
                                justify="center"
                                align="middle"
                                style={{minHeight: '100%'}}
                            >
                                <Col>
                                    <img alt="gitlab" src="/icons/gitlab.png" width={100}/>
                                </Col>
                            </Row>
                        </Button>
                    </Col>
                    <Col span={8}>
                        <Button className="git-install" href={'/git/provider/private-gitlab/redirect'}>
                            <Row
                                type="flex"
                                justify="center"
                                align="middle"
                                style={{minHeight: '100%'}}
                            >
                                <Col>
                                    <img alt="gitlab" src="/icons/gitlab.png" width={100}/>
                                </Col>
                            </Row>
                        </Button>
                    </Col>
                </Row>
            </Card>
        </Modal>)
}


export default function AccessRightsOverview() {
    const {accessRights} = useContext((AccessRightsContext));
    const [gitModal, setGitModal] = useState(false);

    const manageLink = (record) => {
        if (record.provider === "github")
            return "https://github.com/settings/installations"
        if (record.provider === "bitbucket")
            return `https://bitbucket.org/${record.resourceDetails.user}/workspace/settings/addon-management`
        if (record.provider === "azuredev")
            return `https://login.microsoftonline.com/${record.resourceDetails.tenantId}/adminconsent?client_id=${window._env_.AZUREDEV_APP_ID}`
        if (record.provider === "jira")
            return `https://${record.resourceDetails.orgName}.atlassian.net`
    }

    const gitAccessColumns = [
        {
            title: 'Provider',
            dataIndex: 'provider',
        },
        {
            title: 'Account',
            dataIndex: 'provider',
            render: (_, item) => (
                item.resourceDetails.user || item.resourceDetails.account
            ),
        },
        {
            title: 'Access',
            width: 300,
            render: (_, record) => (
                <Space size="middle">
                    <Button icon={<CheckOutlined/>} disabled>
                        Granted
                    </Button>
                    <a target="_blank" rel="noreferrer" href={manageLink(record)}>Manage Access</a></Space>
            ),
        },
    ];

    return <div>
        <GitModal visible={gitModal} onCancel={() => setGitModal(false)}/>
        {/*<TicketModal visible={ticketModal} onCancel={() => setTicketModal(false)}/>*/}
        <Row gutter={gutter}>
            <Col span={24}>
                <PageCard>
                    <h2>Git Providers</h2>
                    <p className="secondary">Connect your infra-repos for automated suggestions</p>
                    <Button type="primary" style={{position: "absolute", top: 10, right: 10}}
                            onClick={() => setGitModal(true)}>Add Git Provider</Button>
                    <Table
                        locale={{emptyText: 'No git providers linked'}}
                        columns={gitAccessColumns}
                        dataSource={accessRights?.gitProviders}
                        pagination={false}
                    />
                </PageCard>
            </Col>
        </Row>
        {/*<Row gutter={gutter}>*/}
        {/*    <Col span={24}>*/}
        {/*        <PageCard>*/}
        {/*            <h2>Ticket Providers</h2>*/}
        {/*            <p className="secondary">Connect your Ticket for FirstMate reactions</p>*/}
        {/*            <Button type="primary" style={{position: "absolute", top: 10, right: 10}}*/}
        {/*                    onClick={() => setTicketModal(true)}>Add Ticket Provider</Button>*/}
        {/*            <Table*/}
        {/*                locale={{emptyText: 'No ticket providers linked'}}*/}
        {/*                onRow={(accessRight) => {*/}
        {/*                    return {*/}
        {/*                        onClick: () => {*/}
        {/*                            accessButtonClicked("ticketProviders", accessRight)*/}
        {/*                        }*/}
        {/*                    };*/}
        {/*                }}*/}
        {/*                columns={ticketAccessColumns}*/}
        {/*                dataSource={accessRights?.ticketProviders}*/}
        {/*                pagination={false}*/}
        {/*            />*/}
        {/*        </PageCard>*/}
        {/*    </Col>*/}
        {/*</Row>*/}

        {/*<AccessRightModal open={openModal} type={focussedAccessRight?.type}*/}
        {/*                  accessRight={focussedAccessRight?.accessRight} onCancel={onCloseModal}*/}
        {/*                  areAllRolesGranted={areAllRolesGranted}/>*/}
    </div>

}
