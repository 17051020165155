import {Button, Form, Input} from "antd";
import * as React from "react";;


export default function PrivateGitlabOnboarding() {

    function save(values) {
        window.location.href = `git/provider/private-gitlab/redirect?accessToken=${encodeURIComponent(values.accessToken)}&url=${encodeURIComponent(values.url)}`;
    }

    return (
        <>
            <h1>Private Gitlab Setup</h1>

            <h3>Create a FirstMate user and provide user token</h3>
            <Form
                name="private-gitlab-setup"
                layout="vertical"
                onFinish={save}
                labelCol={{span: 8}}
                wrapperCol={{span: 16}}
                style={{maxWidth: 800, marginTop: 20}}
                autoComplete="off"
                initialValues={{
                    url: "",
                    accessToken: ""
                }}
            >
                <Form.Item
                    label="Please provide us url of your private gitlab instance."
                    name="url"
                    rules={[{required: true, message: 'Please double check the gitlab url'}]}>
                    <Input placeholder="https://gitlab.organisation.com" style={{width: 300}}/>
                </Form.Item>
                <Form.Item
                    label="Please provide us a user access token of the FirstMate user."
                    name="accessToken"
                    rules={[{required: true, message: 'Please double check the access token'}]}>
                    <Input placeholder="glpat-XXXX" type="password" style={{width: 300}}/>
                </Form.Item>
            </Form>
            <Button type="primary" form="private-gitlab-setup" key="submit" htmlType="submit">
                Next
            </Button>
        </>
    )

}